.conceptA{
  padding: 40px 10px;
  background: url('');
  position: relative;
  overflow: hidden;
  .container{
    position: relative;
    z-index: 3;
    background: rgba(255,255,255,0.8);
    padding: 40px 20px;
  }
  p{
    line-height: 30px;
  }
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_sp1 - 1+px){
      padding: 30px 20px;
      .container{
        padding: 30px 20px;
      }
    }
  }
}



.conceptA__bg{
  z-index: 2;
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.text--center{

}

.conceptB{
  .conceptB__textWrap{
    max-width: 100%;
    flex: 0 0 100%;
  }
  .text--left,.text--left small{
    text-align: left !important;
  }
}

.conceptB-2 {
	.row {
    margin-left: 0;
    margin-right: 0;
		> * {
			padding: 0;
			margin-bottom: 0;
			.conceptB__textWrap {
				padding: 0 60px;
			}
		}
	}
	@media (max-width: $braekpoint_sp1 - 1+px){
		.row {
			> * {
				margin-bottom: 15px;
				.conceptB__textWrap {
					padding: 0 0px;
				}
			}
		}
	}
}

.conceptC{
  position: relative;
  overflow: hidden;
  .conceptC__text{
    position: relative;
    z-index: 3;
    background: rgba(255,255,255,0.9);
    padding: 60px;
    margin: 60px;
    width: 520px;
    &.text--verticalPC,&.text--vertical{
      width: auto;
    }
    &:not(.conceptC__text--right){
      margin-right: auto !important;
    }
  }
  .conceptC__text--right{
    margin-left: auto !important;
  }
  .container:not(.conceptC__bg){
    > .conceptC__text{
      margin: 60px 0px;
    }
  }
  &.conceptC2{
    .conceptC__text{
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  p{
    line-height: 30px;
  }
  .text--verticalPC{
    p{
      line-height: 2;
      height: max-content;
    }
  }

  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_sp1 - 1+px){
      .conceptC__bg.container{
        padding: 30px 20px;
      }
      &.conceptC__bg{
        padding: 30px 20px;
        background-size: cover;
        background-attachment:  inherit;
        &.container{
          background-size: cover;
        }.container{
          padding: 0;
        }
      }

      .conceptC__text{
        position: relative;
        z-index: 3;
        background: #fff;
        padding: 30px 20px;
        margin: 0;
        width: 100% !important;
        p{
          line-height: 24px;
        }
      }
      .conceptC__text--right{
        margin: 0;
      }
    }
  }

  .bgTransparent {
    background: transparent !important;
    @media (min-width: $braekpoint_sp1 +px){
      width: 600px !important;
      padding: 60px 0 !important;
    }
    @media (max-width: $braekpoint_sp1 - 1+px){
      padding: 0 !important;
    }
  }
}


.conceptC__bg{
  z-index: 2;
  overflow: hidden;
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // background-attachment: fixed;
  &.container{
   background-size: $container+px auto;
  }
}

.conceptD{

}
.conceptD__row{
  display: -ms-flex;
  display: flex;
  overflow: hidden;
}
.conceptD__img,.conceptD__text{
  max-width: calc(50% + 30px);
  flex: 0 0 calc(50% + 30px);
}
.conceptD__img{
  position: relative;
  z-index: 2;

  img{
    z-index: 2;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 60px;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: calc(100% - 60px);
    -o-object-fit: cover;
       object-fit: cover;
       @media all and (-ms-high-contrast: none) {
         position: relative;
         height: auto !important;
       }
  }
}
.conceptD__text{
  position: relative;
  z-index: 3;
  margin: 60px 0 0px -60px;
  padding: 60px;
  background: #fff;

  p{
    line-height: 30px;
  }
  // h2,h2 span,h2 small,p{
  //   text-align: left;
  // }
}

.conceptD2{
	.conceptD__text {
		margin: 111px 0 111px -60px;
	}
}

@if $device_mode != 'pc'{
  .conceptD2{
  	.container {
  		@media (max-width: $braekpoint_sp1 - 1+px) {
  			padding: 0;
  		}
  	}
  	.conceptD__text {
  		@media (max-width: $braekpoint_sp1 - 1+px) {
  			margin: -100px 15px 0;
  		}
  	}
  }
  @media (min-width: $braekpoint_sp1+px){
    .conceptD__row--textLeft{
      padding-bottom: 60px;
      .conceptD__img{
        order: 2;
        margin-top: 60px;
      }
      img{
        bottom: -60px;
        height: calc(100% + 60px);
      }

      .conceptD__text{
          order: 1;
          margin: 0px -60px 0px 0;
          padding: 60px;
      }
    }
  }

  @media (max-width: $braekpoint_sp1 - 1+px){
    .conceptD__row{
      flex-wrap: wrap;
    }
    .conceptD__img,.conceptD__text{
      max-width: 100%;
      flex: 0 0 100%;
    }
    .conceptD__img{
      img{
        position: relative;
        bottom: 0;
        height: auto;
      }
    }
    .conceptD__text{
      position: relative;
      z-index: 3;
      margin: -60px 15px 0;
      max-width: calc(100% - 30px);
      padding: 40px 20px;
      background: #000;
      color: #fff;
      h2 span,h2 small{
        color: #fff;
      }

      // h2,h2 span,h2 small,p{
      //   text-align: center;
      // }
      p{
        font-size: 12px;
      }
    }
  }
}


@if $device_mode == 'pc'{
  .conceptD__row--textLeft{
    padding-bottom: 60px;
    .conceptD__img{
      order: 2;
      margin-top: 60px;
    }
    img{
      bottom: -60px;
      height: calc(100% + 60px);
    }

    .conceptD__text{
        order: 1;
        margin: 0px -60px 0px 0;
        padding: 60px;
    }
  }
}


.conceptE__bg{
  position: relative;
  overflow: hidden;
  height: 400px; //　画像の高さを指定
  z-index: 2;
  img{
    position: absolute;
    top: -300%;
    bottom: -300%;
    left: -300%;
    right: -300%;
    margin: auto;
    width: 1920px;
    max-width: none;
  }
  @if $device_mode != 'pc'{
    @media (max-width: 991px){

    }
  }
}

.conceptE__text{
  width: 750px;
  position: relative;
  z-index: 3;
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;
  // background: $color_highlight_bg;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 40px;
  h2{
    margin-bottom: 15px;
  }
  @if $device_mode != 'pc'{
    @media (max-width: 991px){
      padding: 30px 15px;
      width: calc(100% - 30px);
      margin: -50px 15px 0 15px;
    }
  }
}

.bgBlack{
  background: #000;
  color: #fff;
  h2 span,h2 small{
    color: #fff;
  }
  &.conceptC__text{
    background: rgba(0,0,0,0.9);
  }
}
