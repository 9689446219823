.hdr--typeE {
  .hdrTop{
    background: $color_primary;
    color: $color_primary_text;
    p{
      color: $color_primary_text;
    }
    a{
      color: $color_primary_text;
      &::after{
        border-color: $color_primary_text !important;
      }
      &:hover{
        color: $color_primary_text;
      }
    }
  }

  .hdrMiddle{

  }

  .hdrMiddle__left{
    max-width: none;
    flex: 0 0 auto;
  }

  .hdrMiddle__middle{
    max-width: none;
    flex: 2 0 auto;
    padding: 0 40px;
    justify-content: space-between;
    >*{
    }

  }

  .hdrMiddle__right{
    max-width: none;
    flex: 0 0 auto;

  }

  .hdrBottom{
    background: $color_highlight_bg;
    .hdrInner{
      justify-content: space-between;
      .gNav{
        max-width: calc(100% - 300px);
        flex: 0 0 calc(100% - 300px);
        ul{
          justify-content: flex-start;
        }
      }
      .searchForm{
        width: auto;
        max-width: 300px;
        flex: 0 0 300px;
      }
    }
  }
}
