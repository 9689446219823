$color_body_bg:#FEFAF4; //サイト背景
$color_text:#363636; //サイト文字色

$color_highlight_bg: #FAF2E1; //ハイライト
$color_highlight_text: $color_text; //ハイライト文字色

$color_primary:#EE6B00; //メインカラー
$color_primary_text:#ffffff; //メインカラー文字色

$color_secondary:#FFD401; //サブカラー
$color_secondary_text:#ffffff; //サブカラー文字色

$color_cta: $color_primary; //メインカラー
$color_cta_text:#ffffff; //メインカラー文字色

$color_border:#dfdfdf; //ボーダーカラー

$color_white: #FFF;
$color_black: #1b1b1b;

$color_text_link:$color_text;


// ボタン
$color_button_text: #EE6B00;
$color_button_text_hover: $color_button_text;
$color_button_bg: #FFF;
$color_button_bg_hover: #EE6B00;
