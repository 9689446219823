.hdrBottom{
  position: relative;
}
.gNav li.hasChildren > ul,
.gNav li.hasChildren > .megaMenu{
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 100%;
  opacity: 0;
  pointer-events: none;
  background: $color_body_bg;

  &.open{
    pointer-events: all;
    opacity: 1;
    transition: 0.3s;
  }
}

.gNav{
  .megaMenu__inner{
    max-width: $container+px;
    margin: auto;
  }
  .megaMenu{
    padding: 30px 0px 10px;
    border-bottom: 1px solid $color_border;
    li{
      // max-width: 25%;
      // flex: 0 0 25%;
      a{
        width: 100%;
        color: $color_text;
        font-weight: $strong_font_weight;
        text-align: left;
        &:hover{
          color: $color_primary;
          img {
            opacity: 0.8;
          }
        }
        small {
          font-weight: initial;
          margin-top: 5px;
          line-height: 1.6;
          display: block;
          text-align: center;
        }
      }
    }
    .row{
      [class*="col-"]{
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
  }
  .megaMenu--typeB{
    padding: 30px 0px 20px;
    ul.row{
      justify-content: flex-start !important;
      li{
        margin-bottom: 10px !important;
      }
    }
  }

}

.megaMenu__button{
  border: 1px solid $color_border;
  border-radius: 100px;
  padding: 10px 30px 10px 15px;
  &:hover{
    background: $color_highlight_bg;

  }
}


.gNav{
  .downMenu{
    position: relative;
    ul{
      right: auto !important;
      background: rgba($color_primary,0.8) !important;
      display: block;
      min-width: 100%;
      a{
        color: $color_primary_text;
        padding: 8px 15px;
        line-height: 24px;
        display: block;
            white-space: nowrap;
        &:hover{
          background: $color_primary;
          transition: 0.1s;
        }
      }
    }
  }
}

.megaMenu__title{
  width: $container+px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 8px;
  border-bottom: 1px solid $color_text;
  margin-bottom: 14px;
}
.megaMenu__inner + .megaMenu__title{
  margin-top: 20px;
}
.megaMenu__arrow{
  display: flex;
  line-height: 24px;
  padding: 0 15px 0 5px ;
  font-weight: $base_font_weight !important;
  height: 100%;
  &:after{
    left: -10px;
    top: 13px !important;
    border-width: 1px !important;
  }
}
