#fixedNav{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  .hdrSearch--toggle .hdrSearch__wrap{
    top: auto;
    bottom: 100%;
  }
}
