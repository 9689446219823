.itemList {
  p{
    line-height: 24px;
    margin-bottom: 0;
    font-size: 14px;
  }
  .itemPrice{
    font-weight: $strong_font_weight;

  }
  .itemPrice.itemPrice--regular {
    font-weight: $base_font_weight;
    font-size: 12px;
    small{
      font-size: 10px;
    }
    @if $device_mode != 'pc'{
      @media (max-width: $braekpoint_sp1 - 1+px){
        font-size: 11px;
      }
    }
  }
  small{
    font-weight: $base_font_weight;
    font-size: 12px;
    display: inline-block;margin-left: 5px;
  }
  .button{
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}

.itemList__unit{
  line-height: 24px;
}
a.itemWrap{
  display: block;
  line-height: 24px;
  &:hover,&:active{
    color: $color_primary;
    img{
      opacity: 0.8;
    }
  }
}

.itemImg,.itemImg img{
  position: relative;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto 10px;
  display: block;
  text-align: center;
}

.itemList--ranking{
  .itemList__unit{
    position: relative;
    .itemWrap:before{
      content: "";
      z-index: 2;
      display: block;
      position: absolute;
      top: 10px;
      left: 25px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      @if $device_mode != 'pc'{
        @media (max-width: $braekpoint_sp1 - 1+px){
          left: 16px;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
      font-weight: $strong_font_weight;
      font-size: 14px;
      text-align: center;
      background: #fff;
      border: 1px solid $color_border;
      border-radius: 50%;
    }
  }
}
@for $i from 1 through 20 {
  .itemList--ranking .itemList__unit:nth-child(#{$i}) .itemWrap:before,.keywordRanking li:nth-child(#{$i}) a:before { content: "#{$i}"; }
}

.itemList--noButton .button{
  display: none !important;
}

.itemList--typeC{
  .itemList__unit{
    position: relative;
    &:before{
      content: "MORE";
      position: absolute;
      right: 28px;
      bottom: 20px;
      font-size: 12px;
      line-height: 1;
      z-index: 2;
    }
    &:after{
      bottom: 20px;
      top: auto !important;
      right: 20px;
    }
    &:hover,&:active{
      &:before{
        color: $color_primary;
        transition: 0.2s;
        transform: translateY(-3px);
      }
      &:after{
        transition: 0.2s;
        transform:  rotate(45deg) translateY(calc(-50% - 3px));
      }
    }
  }
  .itemWrap{
    position: relative;
    transition: 0.1s;
    padding: 15px;
    box-shadow:0px 2px 6px 0px rgba(0,0,0,0.25);
    border-radius: 4px;
    background: #fff;
    height: 100%;

    &:hover,&:active{
      transition: 0.2s;
      box-shadow:0px 4px 10px 0px rgba(0,0,0,0.3);
      transform: translateY(-3px);
    }
    .itemPrice{
      padding-right: 60px;
    }

    @if $device_mode != 'pc'{
      @media (max-width: $braekpoint_tab+px) {
        padding: 15px 15px 35px 15px;
        &:before{
          color: $color_primary;
          right: 23px;
          bottom: 15px;
        }
        &:after{
          border-color: $color_primary !important;
          bottom: 15px;
          right: 15px;
        }
        .itemPrice{
          padding-right: 0px;
        }
      }
    }
  }
}

.itemList--typeD{
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_sp2+px){
      margin-left: -5px;
      margin-right: -5px;
      .itemList__unit{
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .itemWrap{
    transition: 0.1s;
    border: 1px solid $color_border;
    border-radius: 6px;
    padding: 15px;
    @if $device_mode != 'pc'{
      @media (max-width: $braekpoint_tab+px) {
        padding: 10px;
      }
    }
    background: #fff;
    &:hover,&:active{
      background: $color_highlight_bg;
      transition: 0.2s;
      box-shadow:0px 2px 4px 0px rgba(0,0,0,0.25);
      transform: translateY(-3px);
      &:before{
        color: $color_primary;
      }
    }
    .button{
      box-shadow: none;
    }
  }
}



@if $device_mode != 'pc'{
  @media (max-width: 767px){
    .itemList .button,
    .itemList a.button{
      font-size: 12px;
      padding: 5px;
      padding-left: 20px;
      padding-right: 20px !important;
      &.arrow::after{
        right: 8px;
      }
    }
  }
}


.itemList--rankingCustom{
  .itemList__unit{
    &::before{
      content: none !important;
    }
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) .itemWrap__text:before { content: "#{$i}"; }
    }

    .itemWrap__text{
      position: relative;
      &:before{
        content: "";
        z-index: 2;
        display: block;
        top: 10px;
        left: 25px;
        width: 24px;
        height: 24px;
        font-weight: $strong_font_weight;
        font-size: 11px;
        line-height: 24px;
        text-align: center;
        background: #000;
        color: #fff;
        border-radius: 50%;
        margin-bottom: 10px;
      }
    }
  }
  a.itemWrap{
    display: -ms-flex;
    display: flex;
    .itemWrap__img{
      max-width: 100px;
      flex: 0 0 100px;
    }
    .itemWrap__text{
      padding-left: 15px;
      max-width: calc(100% - 100px);
      flex: 0 0 calc(100% - 100px);
    }
  }
}

.contentsSlider{
  &.itemList .slick-list {
    padding: 5px 0;
  }
  // &.slick-dotted.slick-slider{
  //   margin-bottom: calc(30px + #{$pc_section_margin_bottom}px);
  //   @media (max-width: $braekpoint_sp1+px){
  //     margin-bottom: calc(30px + #{$sp_section_margin_bottom}px);
  //   }
  // }
  .itemList__unit{
    margin-bottom: 0;
  }
  .slick-arrow{
      transform-origin: top left top left;
      margin: 0;
      display: block;
    &:before{
      content: none;
    }
    width: 40px;
    height: 40px;
    transform-origin: top;
  }
  .slick-next,.slick-prev{
    z-index: 2;
  }
  .slick-next{
    transform: rotate(45deg) translate(0, -50%);
    border-top: $color_text 1px solid;
    border-right: $color_text 1px solid;
    right: -30px;
  }
  .slick-prev{
    transform: rotate(45deg) translate(0, -50%);
    border-bottom: $color_text 1px solid;
    border-left: $color_text 1px solid;
    left: -30px;
  }
  @media (max-width: $container + 40+px){
    .slick-arrow{
      width: 20px;
      height: 20px;
          transform-origin: top;
    }
    .slick-next{
      right: 0px;
    }
    .slick-prev{
      left: 00px;
    }
  }
}

.itemList--ranking{
  &.ranking--typeB,
  &.ranking--typeC,
  &.ranking--typeD{
    .itemList__unit{
      .itemWrap:before{
        background: $color_body_bg;
      }
    }
  }
  &.ranking--typeB,
  &.ranking--typeC,
  &.ranking--typeD,
  &.ranking--typeE{
    .itemList__unit{
      .itemWrap:before{
        font-size: 20px;
        @if $device_mode != 'pc'{
          @media (max-width: $braekpoint_sp1 - 1+px){
            font-size: 16px;
          }
        }
        border-radius: 0;
        border: 0;
      }
    }
  }
  &.ranking--typeB{
    .itemList__unit{
      position: relative;
      .itemWrap:before{
        border-bottom: 1px solid $color_primary;
        border-right: 1px solid $color_primary;
      }
    }
  }

  &.ranking--typeC{
    .itemList__unit{
      position: relative;
      .itemWrap:before{
        border: 1px solid $color_text;
      }
    }
  }

  &.ranking--typeD{
    .itemList__unit{
      position: relative;
      .itemWrap:before{
        border-bottom: 2px solid $color_border;
        border-right: 2px solid $color_border;
      }
      &:nth-child(1){
        .itemWrap:before{
          background: #FCE180;
          border-color: #E2B100;
        }
      }
      &:nth-child(2){
        .itemWrap:before{
          background: #EAEAEA;
          border-color: #C8C8C8;
        }
      }
      &:nth-child(3){
        .itemWrap:before{
          background: #FFD7B2;
          border-color: #C06108;
        }
      }
    }
  }

  &.ranking--typeE{
    .itemList__unit{
      position: relative;
      .itemWrap:before{
        top: 0;
        height: 45px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 80%, 0 100%); //IE11未対応
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 80%, 0 100%); //IE11未対応
      }
    }
  }
  &.ranking--typeF{
    .itemWrap{
      overflow: hidden;
      position: relative;
      &:before{
        background: transparent !important;
        border: none !important;
        z-index: 3;
        top: -2px;
        left: -2px;
        font-size: 16px;
        color: $color_primary_text;
      }
      &:after{
        content: "" !important;
        display: block;
        position: absolute;
        top: -40px;
        left: -40px;
        width: 80px;
        height: 80px;
        background: $color_primary;
        transform: rotate(45deg);
        z-index: 2;
      }
      @if $device_mode != 'pc'{
        @media (max-width: $braekpoint_sp1 - 1+px){
          &:before{
            top: -3px;
            font-size: 14px;
          }
          &:after{
            top: -30px;
            left: -30px;
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
}

.itemPrice--default {
  text-decoration: line-through;
  opacity: 0.5;
    font-size: 12px;
}
.itemPrice--saleRate,
p.itemPrice--saleRate{
  display: block;
  margin: 8px 0 0 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: $color_cta;
  color: $color_cta_text;
  padding: 2px 14px;
}
.itemSmallStock {
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  right: 0;
  color: $color_cta_text;
  background: rgba($color_cta,0.75);
  padding: 2px 14px;
}
.itemPrice--sale{
  color:#cc0000;
}
.itemImg{
  .itemSoldout {
      position: absolute;
      top: 50%;
      background: rgba(0,0,0,0.35);
      color: #fff;
      padding: 10px;
      left: 0px;
      right: 0px;
      width: auto;
      transform: translateY(-50%);
  }
  .itemPrice--saleRate {
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0;
  }
}

@media (max-width: $braekpoint_sp2 - 1+px) {
  .spRanking{
    >.itemList__unit:first-child{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.contentsSlider .itemList__unit{
  max-width:100%!important;
  flex-basis:100%!important;
}


.contentsSlider {
	&.noDots{
	  .slick-dots{
	    display: none !important;
	  }
	}
}

.itemArrange--typeF {
	&.itemArrange--typeF2 {
		.itemArrange--typeF_imgbox {
			position: relative;
			.itemArrange--typeF_imgbox_txtbox {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				display: flex;
				justify-content: center;
				align-items: center;
				// 下記、白文字設定。外せば通常のボタンカラー
				.itemArrange--typeF_imgbox_txtinner {
					color: #fff;
					.button--typeB {
						border-color: #fff;
						color: #fff;
						background: none;
						&.arrow:after {
							border-color: #fff;
						}
					}
				}
			}
		}
	}
	.itemArrange--typeF_title {
		margin-bottom: 25px;
	}
	.row {
		.itemArrange--typeF_sliderbox {
			width: 100%;
			padding: 0 35px;
			.slick-prev {
				left: 0;
			}
			.slick-next {
				right: 0;
			}
		}
	}
	@media (max-width: $braekpoint_sp1 - 1+px) {
		&.itemReverce {
			.row {
				flex-direction: column-reverse;
			}
		}
		.bnrBox {
			text-align: center;
			.button {
				display: block;
				width: 100%;
			}
		}
		.itemArrange--typeF_imgbox {
			margin-left: -$sp_base_grid_margin+px;
	    margin-right: -$sp_base_grid_margin+px;
	    padding: 0;
	    flex: 0 0 100vw;
	    width: 100vw;
	    max-width: 100vw;
		}
		.itemArrange--typeF_title {
			margin-bottom: 30px;
		}
		.row {
			.itemArrange--typeF_sliderbox {
				padding: 0;
			}
		}
	}
}
