.shoppingGuide{
  text-align: center;
  &.highlight{
    // padding: 30px 0;
    [class*="col"] .inner{
      background: #fff;
    }
  }
  [class*="col"]{
    margin-bottom: 0;
  }
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_sp1 - 1+px){
      [class*="col"]{
        margin-bottom: 15px;
      }
    }
  }

  .inner{
    padding: 20px 10px;
  }
  h3{
    font-size: 18px;
    line-height: 24px;
    font-weight: $head_font_weight;
    color: $color_primary;
    margin-bottom: 7px;
  }
  picture{
    margin: 8px 0;
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 60px;
  }
  p{
    font-size: 14px;
    line-height: 24px;
    font-weight: $strong_font_weight;
    margin-bottom: 0px;
    @if $device_mode != 'pc'{
      @media (max-width: $braekpoint_sp1+px) {
        font-size: 12px;
      }
    }
    small{
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
    }
  }
  i.material-icons {
    font-size: 48px;
    margin: 10px auto;
  }
}

.shoppingGuide__title{
  text-align: center;
  font-size: 24px;
  font-weight: $strong_font_weight;
  border-bottom: 2px solid $color_primary;
  line-height: 1.5;
  padding: 8px 0;
  margin-bottom: 30px;
  i{
    display: inline-block;
    font-size: 28px;
    margin-right: 14px;
    vertical-align: middle;
  }
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_sp1+px) {
      font-size: 16px;
    }
  }
}


// ご利用ガイド
.userGuide {
  h2 {
    border-color: $color_text;
    padding: 5px 0;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  i {
    vertical-align: middle;
    margin-right: 10px;
  }
  h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    & + h3 {
      margin-top: 24px;
    }
  }
}
.userGuide__card{
  list-style: none;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 -5px 24px;
  li{
    list-style: none;
    padding: 0 5px;
  }
}
.userGuide__info {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 10px;
  background: #fff;
  text-align: center;
  font-weight: $strong_font_weight;
  span {
    font-size: 16px;
  }
  strong {
    color: $color_primary;
    font-size: 16px;
  }
}
.userGuide__btn {
  display: inline-block;
  padding: 12px 24px 12px 12px;
  font-size: 14px;
  font-weight: $strong_font_weight;
  background: #fff;
  border: 1px solid $color_border;
  border-radius: 5px;
  margin-bottom: 24px;
  &::after {
    right: 10px !important;
  }
  &:hover {
    color: $color_primary;
  }
  i {
    font-size: 20px;
    color: $color_primary;
  }
}
.userGuide__tel {
  margin-bottom: 24px;
  p {
    margin-bottom: 5px;
    font-weight: $strong_font_weight;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -1px;
  }
  i {
    margin-right: 8px;
    font-size: 20px;
    vertical-align: middle;
    line-height: 24px;
    color: $color_primary;
  }
}
@media (max-width: $braekpoint_tab - 1+px) {
  .userGuide__btn{
    width: 100%;
    text-align: center;
  }
  .userGuide {
    .col-sp-12 {
      margin-bottom: 30px;
    }
    h3 {
      font-size: 14px;
    }
  }
}

.userGuide__btn.arrow {
  padding-right: 25px;
}

@media (min-width: 768px) {
  .userGuide{
    .hasChildren--clickSP > .mb30{
      &:last-child{
        margin-bottom: 0 !important;
      }
    }
  }
}
@media (max-width: 767px) {
  .mb0--sp{
    margin-bottom: 0 !important;
  }
  .userGuide{
    .hasChildren--clickSP{
      >div{
        display: none;
      }
      >h2{
        position: relative;
        cursor: pointer;
        &:before,
        &:after{
          width: 10px;
          height: 2px;
          transform:translateY(-50%);
          border: 0;
          right: 13px;
          background: $color_text;
          display: block;
          position: absolute;
          transition: 0.15s;
          top: 50%;
        }

        &:after{
          content: "";
        }

        &:before{
          content: "";
          display: block;
          position: absolute;
          transition: 0.15s;
          top: 50%;
          transform: rotate(90deg) translateY(-50%);
          transform-origin: top center;
        }
        &.open{
          &:before,
          &:after{
          }
          &:before{
            opacity: 0;
            transform: translateY(-50%);
          }
          &:after{
          }
        }
        &:hover{
          &:before,
          &:after{
            background: $color_primary;
            right: 13px !important;
          }
          &:after{
          }
        }
      }
    }
  }
}
