.tabContent{
  display: none;
  &.active{
    display: block;
  }
}
.tabSwitch{
  padding-bottom: 15px;
  margin-bottom: 10px;
  span{
    display: block;
    text-align: center;
  }
  li{
    text-align: center;
  }
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_tab+px) {
      &.scrollX{
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        overflow: scroll;
        overflow-x: scroll;
        overflow-y: visible;
        li{
          white-space: nowrap;
          -webkit-box-flex: auto;
          -ms-flex-positive: auto;
          flex-grow: auto;
        }
      }
      > li{
        font-size: 11px !important;
      }
    }
  }
}
.tabSwitch, .tabSwitch > li{
  list-style: none;
  -webkit-transition: 0.15s;
  transition: 0.15s;
  cursor: pointer;
  &:hover{
    -webkit-transition: 0.15s;
    transition: 0.15s;
  }
}

.tab--typeA{
  .tabSwitch{
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-left: 0;
    margin-right: 0;
    li{
      pointer-events: all;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      background: $color_highlight_bg;
      font-size: 16px;
      padding: 12px;
      line-height: 24px;
      @media (max-width: 767px) {
        padding: 8px;
        line-height: 1.5;
      }
      font-weight: $strong_font_weight;
      margin-bottom: 0;
      position: relative;
      &:not(:last-child){
        border-right: 1px solid $color_border;
      }
      &:hover{
        color: $color_primary;
      }
      &:after{
        -webkit-transition: 0.15s;
        transition: 0.15s;

        content: "";
        display: block;
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 15px 0 15px;
        border-color: transparent transparent transparent transparent;
      }
      &.active{
        background: $color_primary;
        color: $color_primary_text;
        border-right: 1px solid $color_border;
        -webkit-transition: 0.15s;
        transition: 0.15s;
        &:after{
          -webkit-transition: 0.15s;
          transition: 0.15s;
          border-color: $color_primary transparent transparent transparent;
        }
      }
    }


  }
}
.tab--typeB{
  .tabSwitch{
    li{
      pointer-events: all;
      border-bottom: 4px solid transparent;
      padding: 15px 40px;
      font-size: 16px;
      font-weight: $strong_font_weight;
      position: relative;

      &:hover{
        color: $color_primary;
      }
      &:after{
        content: "";
        position: absolute;
        top: 15px;
        bottom: 15px;
        right: 0;
        display: block;
        width: 1px;
        background: $color_border;
      }
      &.active{
        border-bottom: 4px solid $color_primary;
        font-weight: $strong_font_weight;
        color: $color_primary;
      }
    }
    @media (max-width: $braekpoint_tab+px) {
      li{
        padding: 15px;
        font-size: 12px;
      }
    }
  }
}
.tab--typeC{
  .tabSwitch{
    li{
      pointer-events: all;
      padding: 0 5px;
      min-width: auto;
      >span{
        display: block;
        border-radius: 100px;
        min-width: auto;
        border: 2px solid $color_border;
        padding: 6px 40px;
        line-height: 24px;
        font-weight: $strong_font_weight;
        -webkit-transition: 0.15s;
        transition: 0.15s;
      }
      &:hover{
        span{
          color: $color_primary;
          background: $color_highlight_bg;
          -webkit-transition: 0.15s;
          transition: 0.15s;
        }
      }
      &.active{
        >span{
          border: 2px solid $color_primary;
          color: $color_primary_text;
          background: $color_primary;
        }
      }
    }
  }
}

.tabSwitch--2row > li {
    max-width: 50% ;
    flex: 0 0 50% ;
    text-align: center;
}

.tabSwitch--3row > li {
    max-width: 33.333% ;
    flex: 0 0 33.333% ;
    text-align: center;
}

.tabSwitch--4row > li {
    max-width: 25% ;
    flex: 0 0 25% ;
    text-align: center;
}
