.gNav.gNav--typeD{
  >ul>li{
    >a{
      display: block;
      >span{
        display: block;
        font-size: 12px;
        &:last-child{
          margin-top: 10px;
        }
      }
      @media all and (-ms-high-contrast:none){
        line-height: 1.4;
        >*{
          flex-basis: auto;
        }
      }
    }
    &.hasChildren{
      >a{
        padding-right: 0;
      }
      >a:after{
        content: none;
      }
      >a{
        >span{
          &:last-child{
            padding-right: 10px;
            padding-bottom: 1px;
            &:after{
              right: 0;
            }
          }
        }
      }
    }
  }
}
