.iconNav{
  > ul {
    display: -ms-flex;
    display: flex;
    align-items: center;
    > li{
      display: block;
      // &:not(:last-child){
      //   margin-right: 10px;
      // }
    }
  }

  span,
  label,
  a{
    display: block;
    padding: 5px;
    text-align: center;
    &:hover{
      // background: $color_highlight_bg;
      img,i{
        opacity: 0.8;
        color: $color_primary;
      }
    }
    small{
      display: block;
      text-align: center;
      font-size: 10px;
      letter-spacing: 0;
      font-weight: $strong_font_weight;
    }
  }
  i{
    font-size: 30px;
    @if $device_mode != 'pc'{
      @media (max-width: $container+px) {
        font-size: 24px;
      }
    }
  }
  img{
    display: inline-block;
    @media (min-width: 768px) {
      margin: 0 5px;
    }
  }
  [class*="hasChildren"]{
    &:not(:last-child){
      margin-right: 10px;
    }
    > ul {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      background: #fff;
      padding: 10px;
      z-index: 2;
      transition: 0.15s;
      a{
        padding: 5px;
        line-height: 24px;
      }
      @if $device_mode != 'pc'{
        @media (max-width: $container+px) {
          right: 0;
          top: 100%;
        }
        @media (max-width: $braekpoint_tab+px) {
          padding: 0;
          left: 0;
          border-top: 1px solid $color_border;
          top: calc(100% - 15px);
          &.open{
            top: 100%;
          }
          a{
            border-bottom: 1px solid $color_border;
            padding: 15px;
            text-align: left;
            font-weight: $strong_font_weight;
          }
          .hasChildren--close{
            display: block;
            background: $color_text;
            a{
              color: #fff;
              vertical-align: middle;
              i{
                vertical-align: middle;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    > a:after{
      left: auto;
      right: -4px
    }

    > ul.open {
        opacity: 1;
        pointer-events: all;
    }
  }
}
@media (min-width: 768px) {
.iconNav--image{
  [class*="hasChildren"]{
    >a:after{
        right: 0;
      }
    }
  }
}

.hasChildren--close{
  display: none;
}

#menu-icon {
  width: 24px;
  height: 22px;
  display: block;
  transition: all 0.4s;
  position: relative;
  span {
    display: inline-block;
    transition: all 0.4s;
    padding: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color_text;
    // border-radius: 4px;
    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 5px;
    }

    &:nth-of-type(3) {
      top: 10px;
    }
  }
  small{
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 10px;
    line-height: 1;

  }
}
.hdrSearch--toggle{
  margin-left: 5px;
}


.bottomNav{
  background: $color_primary;
  color: $color_primary_text;
  padding: 10px;
  ul{
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    li{
      margin: 0 2px;
      max-width: 20%;
      flex: 0 0 20%;
      a{
        color: $color_primary_text;
        display: block;
      }
    }
  }

}
.bottomNav__icon{
  display: block;
  text-align: center;
  line-height: 1;
}

.bottomNav__text{
  display: block;
  text-align: center;
  font-size: 10px;
  margin-top: 3px;
  line-height: 1;
}


.iconNav__cart{
  position: relative;
  .cartAmount{
    position: absolute;
    top: 0px;
    right: -2px;
    height: 15px;
    width: 15px;
    background: $color_primary;
    color: $color_primary_text;
    border-radius: 50%;
    font-size: 10px;
  }
}


.iconNav--text{
  >ul>li{
    a{
      padding: 0;
      line-height: 1;
    }
    .iconNav__cart .cartAmount{
      right: -5px;
      top: -5px;
    }
    &:not(:last-child){
      margin-right: 24px;
      &[class*="hasChildren"]{
        margin-right: 29px;
        @media (max-width: $braekpoint_tab - 1+px) {
          margin-right: 10px;
        }
      }
    }
    small{
      display: block;
      text-align: center;
      margin-top: 5px;
      @if $device_mode != 'pc'{
        @media (max-width: $braekpoint_tab - 1+px) {
          display: none !important;
        }
      }
    }

  }
}
