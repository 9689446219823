/* ************************************************
*  all
* ************************************************ */
.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
}

.clearfix {
    min-height: 1px;
}

* html .clearfix {
    height: 1px;
    /*\*//*/
    height: auto;
    overflow: hidden;
    /**/
}

img{
  max-width: 100%;
}

/* ************************************************
*  cloud_zoom
* ************************************************ */
/* This is the moving lens square underneath the mouse pointer. */
.cloud-zoom-lens {
  margin:-4px;  /* Set this to minus the border thickness. */
  border: 4px solid #888;
  background-color:#fff;
  cursor:move;
}

/* This is for the title text. */
.cloud-zoom-title {
  position:absolute !important;
  top:0px;
  padding:3px;
  width:100%;
  background-color:#000;
  color:#fff;
  text-align:center;
  font-weight: $strong_font_weight;;
  font-size:10px;
  font-family:Arial, Helvetica, sans-serif;
}

/* This is the zoom window. */
.cloud-zoom-big {
  overflow:hidden;
  border: 4px solid #c86464;
}

/* This is the loading message. */
.cloud-zoom-loading {
  padding:3px;
  border:1px solid #000;
  background:#222;
  color:white;
}

/* Feature section in ZoomEngine page */
.zoom-section {
  clear:both;
}

* html .zoom-section {
  display:inline;
  clear:both;
}

.zoom-small-image {
  float: left;
  margin-top: 4px;
  width: 100%;
}
#wrap {
  position: relative;
  top: 0px;
  z-index: 10;
}

.cloud-zoom {
  margin-bottom: 20px;
}

.mousetrap {
  position: absolute;
  top:0px;
  left:0px;
  z-index:999;
  width: 100%;
}



/* ************************************************
*  product_option_window
* ************************************************ */

#product_option {
  margin: 20px;
  padding: 20px;
  background: #fff;
}
#product_option h2 {
  margin-bottom: 20px;
}

table.table_option {
  margin-bottom: 20px;
  border-top: 1px solid $color_border;
  border-right: 1px solid $color_border;
  background: #fff;
}
table.table_option th {
  padding: 10px;
  border-bottom: 1px solid $color_border;
  border-left: 1px solid $color_border;
  background: $color_highlight_bg;
}
table.table_option td {
  padding: 10px;
  border-bottom: 1px solid $color_border;
  border-left: 1px solid $color_border;
}


/* ************************************************
*  policy
* ************************************************ */

#policy {
  line-height: 1.8;
  font-size: 14px;
  padding-top: 30px;
}
#policy h2 {
  margin-bottom: 30px;
}

.policy_box,
#policy_lead{
  margin-bottom: 30px;
}

.sk_box {
  margin-bottom: 70px;
}
.sk_box h3 {
  margin-bottom: 1em;
  font-size: 16px;
}
.sk_box table {
  width: 100%;
  border-top: 1px solid $color_border;
  border-collapse: separate;
  empty-cells:show;
}

.sk_box th {
  padding: 20px 40px 20px 0;
  width: 170px;
  text-align: left;
}
.sk_box th img.poricy_img {
  margin-bottom: 5px;
}
.sk_box td {
  padding: 20px 0;
  zoom: 1;
  content: ' ';
}
.sk_box th,
.sk_box td {
  vertical-align: top;
  line-height: 1.8;
  position: relative;
  border-bottom: solid 1px #ccc;
}

#payment_method {
  margin-top: 40px;
}
#payment_method table.table {
  width: 100%;
}
#payment_method table.table th {
  width: 200px;
  text-align: center;
}

#shipping_method {
  margin-top: 40px;
}
#shipping_method table.table {
  width: 100%;
}
#shipping_method table.table th {
  width: 200px;
  text-align: center;
}

/* ************************************************
*  Common Elements
* ************************************************ */
/* @group Common Elements */

table {
  border-spacing: 0;
  border-collapse: collapse;
}
table.table {
  margin-bottom: 20px;
  border-top: 1px solid $color_border;
  border-right: 1px solid $color_border;
}
table.table th {
  padding: 5px;
  border-bottom: 1px solid $color_border;
  border-left: 1px solid $color_border;
  background: $color_highlight_bg;
}
table.table td {
  padding: 5px;
  border-bottom: 1px solid $color_border;
  border-left: 1px solid $color_border;
}

.footstamp {
  margin-bottom: 30px;
}
.footstamp span {
  margin: 0 5px;
}

.footstamp p{
  margin-bottom: 0px;
  line-height: 24px;
}
@media screen and (max-width:767px){
  .footstamp {
    margin: 15px 0;
  }
  .footstamp p{
    font-size: 12px;
    &:nth-child(n+2){
      display: none;
    }
  }
}
.prd_search_hit {
  clear: both;
  margin: 2em 0;
}
.searchTitle{
  font-weight: $strong_font_weight;
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height:1.6;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.sort {
  padding: 10px 0 0;
  text-align: center;
  margin-bottom: 15px;
}
.sort strong {
  margin-right: 5px;
}
.sort a {
  margin-right: 5px;
  text-decoration: underline;
}
.sort span {
  margin-right: 5px;
  text-decoration: none;
  font-weight:  $strong_font_weight;;
}

.pager {
  clear: both;
  padding: 5px 0 10px 0;
  margin: 0 auto 30px;
  text-align: center;
  margin-top: 0 !important;

}
.pager a{
  margin: 15px;
}
@media (max-width: 767px) {
  .sort,.pager{
    font-size: 12px;
  }
}

.none {
  clear: both;
  margin: 50px 0;
  text-align: center;
}

.sold_out,.itemList__soldOut {
  color: #DA5858;
  font-family:$base_font_family;
}

.tbl_calendar {
  margin: 20px 0;
  width: 100%;
  border: none !important;
  border-spacing: 0 !important;
  border-collapse: collapse !important;
}
.tbl_calendar th, .tbl_calendar td {
  padding: 4px !important;
  border-bottom: 1px solid $color_border !important;
  text-align: center !important;
}


/*ここから調整*/

#container{
  margin-top:30px;
  overflow: hidden;

}

#container.topPage{
  margin-top: 0;
}
#container:not(.topPage) {
}
@media (max-width: $braekpoint_tab+px){
  #container{
    margin-top:15px;
  }
  #container:not(.topPage) #main {
    float: none;
    width: auto;
    overflow: hidden;
    padding: 0 $sp_bezel+px;
  }
  #container:not(.topPage) #side {
    display: none;
    float: none;
  }
}

/*商品詳細追加分*/
.subImageList{
  margin:15px 0px 20px;
  padding:0px 15px;
}
.subImageList__unit{
  width:22%;
  text-align:center;
  float:left;
}
.subImageList__unit img{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width:100%;
  cursor: pointer;
}
.subImageList__unit:nth-child(n+2){
  margin-left:4%;
}
.subImageList__unit:nth-child(4n+1){
  margin-left:0%;
  clear:both;
}
.subImageList__unit:nth-child(n+5){
  margin-top:15px;
}

/*拡大画像一覧*/
.lImageList{
  margin:15px 0px 60px;
}
.lImageList__unit{
  text-align:center;
}
.lImageList__unit:nth-child(n+2){
  margin-top:25px;
}

.cartBottom{
  margin-top:20px;
  margin-bottom:70px;
}
.cartBack{
  width:75%;
}
.cartBottom .btn-primary{
  background-position:30% 45%;
  margin:0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left:20px;
}
.detailTitle{
  font-size:14px;
  font-weight: $strong_font_weight;;
  padding:13px 0px;
  border-top:solid 1px #ddd;
  border-bottom:solid 1px #ddd;
  margin-bottom:20px;
}

.membarNameTrue {
    margin-right: 30px;
    display: block;
}



/* カートボタン */
button.detail__cartBtn ,
button.detail__contactBtn,
a.detail__contactBtn{
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
button.detail__cartBtn {
  width: 100%;
  height: 60px;
  background-color: $color_cta;
  border: 1px solid $color_cta;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
  text-decoration: none;
  color: #fff !important;
  font-weight: $strong_font_weight;
  -webkit-box-align: center;
  box-align: center;
  border-radius: 5px;
  margin: 30px 0 15px;
  font-family: $base_font_family;
}
button.detail__cartBtn *{
  line-height: 1;
}
button.detail__contactBtn,
a.detail__contactBtn{
  font-size: 12px;
  border-radius: 5px;
  color: #000 !important;
  font-weight: $strong_font_weight;
  text-align: center;
  background-color: #fff;
  border: 1px solid #000;
  padding: 0 15px;
  line-height: 40px;
  height: 40px;
  text-decoration: none;
  margin: 0px 0 30px;
}

#productDetail i{
  display: inline-block;
  margin-right: 5px;
}



.taglist {
  margin: 0 0 20px;
}
.taglist p {
  margin: 0 0 10px;
}
.taglist .button--tag {
  margin: 0 6px 10px 0;
  display: inline-block;
}

.button--line {
  border: 1px solid $color_text;
  color: $color_text;
}

.button--tag {
    font-size: 12px;
    padding: 5px 8px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

// ページャー
.pagerListNav{
  margin-bottom: 60px;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}
ul.pagerList {
  display: flex;
  justify-content: center;
  list-style: none;
  li{
    list-style: none;
    >span, >a{
      padding: 0 10px;
      text-align: center;
      display: block;
      font-weight: $strong_font_weight;
      color: $color_primary;
      font-size: 20px;
      @media (max-width: 767px) {font-size: 12px;}
    }
    &.current{
      >span{
        color: $color_text;
      }
    }
  }
}
.pageList--amount{
  font-size: 16px;
  text-align: center;
  margin-top: 15px;
  @media (max-width: 767px) {font-size: 12px;}
}
@media (max-width: 767px) {
  #detail__productImage{
    margin: 0 -$sp_bezel+px;
     .slick-dots{
       padding: 0 $sp_bezel+px;
     }
  }
}

// カレンダー
.tbl_calendar {
  border: none !important;
  border-collapse: collapse !important;
  border-spacing: 0 !important;
}
.tbl_calendar th, .tbl_calendar td {
  padding: 4px !important;
  text-align: center !important;
  border-bottom: 1px solid #ccc !important;
}

.mainCalendar {
  display: flex;
  justify-content: space-between;
}
.mainCalendar table.tbl_calendar {
    max-width: calc(50% - 30px);
}
.mainCalendar table.tbl_calendar caption{
  font-weight: $strong_font_weight;
    margin: 0px auto 15px;
    font-size: 18px;
}
@media (max-width: 991px) {
  .mainCalendar {
    display: block;
  }
  .mainCalendar table.tbl_calendar {
      max-width: 100%;
  }
}
