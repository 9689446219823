table.shopInfoTable{
  width: 100%;
  font-size: $base_font_size+px;
  line-height: 24px;
  @if $device_mode != 'pc'{
  @media (max-width: $braekpoint_sp1 - 1+px) {
    font-size: $base_font_size_sp+px;
    line-height: 20px;
  }
}
  margin-bottom: 20px;
  th{
    font-weight: $base_font_weight;
    text-align: left;
    line-height: 24px;
    padding: 2px 0;
    border: none;
    @media (max-width: $braekpoint_sp1 - 1+px){
      width: 25%;
    }
  }
  td{
    border: none;
    line-height: 24px;
    padding: 2px 0 2px 10px;
    text-align: left;
  }
}

.shopInfo--text{
  h3{
    margin-top: 20px;
  }
  &.alignCenter{
    h3{
      margin-top: 0;
    }
    table.shopInfoTable{
      margin-bottom: 0;
    }
  }
}
