.btnNav{
  flex: 0 0 auto;
  a{
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    border: 1px solid $color_border;
    border-radius: 5px;
    padding: 6px 30px 6px 10px;
    line-height: 24px;
    font-weight: $strong_font_weight;
    i{
      vertical-align: middle;
      display: inline-block;
      margin-right: 5px;
      color: $color_primary;
    }
    &.arrow{
      &:after{
        right: 15px;
      }
    }
    &:hover{
      color: $color_primary;
      background: $color_highlight_bg;
      &:after{
        right: 10px;
      }
    }
  }
}

.hdrMiddle__middle .btnNav:nth-child(2){
  text-align: right;
}
