.searchForm .searchForm__inner{
  background: #fff;
}
.hdr--typeB .hdrBottom{
  background: #FEFAF4;
}
.iconNav__cart .cartAmount{
  right: 5px;
  top:4px;
}
.searchForm .searchForm__submit{
  padding: 0 20px 0 5px;
}
.sec-banner .row{
  justify-content: space-between;
  gap:30px;
}
.itemList--ranking .itemList__unit .itemWrap::before{
  content: "";
  width:70px;
  height: 143px;
  border: none;
  border-radius: 0;
  background-size: contain;
  top:-55px;
}
.itemList--ranking .itemList__unit:nth-child(1) .itemWrap:before{
  content: "";
  background-color: transparent;
  background-image: url('../img/sec03_icon01.png');  
}
.itemList--ranking .itemList__unit:nth-child(2) .itemWrap:before{
  content: "";
  background-color: transparent;
  background-image: url('../img/sec03_icon02.png');  
}
.itemList--ranking .itemList__unit:nth-child(3) .itemWrap:before{
  content: "";
  background-color: transparent;
  background-image: url('../img/sec03_icon03.png');  
}
.itemList--ranking .itemList__unit:nth-child(4) .itemWrap:before{
  content: "";
  background-color: transparent;
  background-image: url('../img/sec03_icon04.png');  
}
.itemList--ranking .itemList__unit:nth-child(5) .itemWrap:before{
  content: "";
  background-color: transparent;
  background-image: url('../img/sec03_icon05.png');  
}
.itemList--ranking .itemList__unit:nth-child(6) .itemWrap:before{
  content: "";
  background-color: transparent;
  background-image: url('../img/sec03_icon06.png');  
}
.itemList--ranking .itemList__unit:nth-child(7) .itemWrap:before{
  content: "";
  background-color: transparent;
  background-image: url('../img/sec03_icon07.png');  
}
.itemList--ranking .itemList__unit:nth-child(8) .itemWrap:before{
  content: "";
  background-color: transparent;
  background-image: url('../img/sec03_icon08.png');  
}
.sec-concept .h2C small{
  color: inherit;
}
.sec-concept p{
  line-height: 25px;
  letter-spacing: 1px;
  margin-bottom: 40px;
}
.sec-shop .row{
  align-items: flex-end;
}
.sec-shop .h3A{
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 24px;
}
.sec-shop img{
  display: block;
  margin:0 auto;  
}
.sec-shop .container > picture img{
  margin-bottom: 60px;
}
.userGuide__btn.arrow{
  font-family: $heading_font_family;
  letter-spacing: 0;
}
.userGuide__btn.arrow i{
  margin-right: 8px;
}
#drawer,.fNav__list li.hasChildren--click > a, .drawer__nav li.hasChildren--click > a{
  background-color:#FEFAF4;
}
.drawer__itemList h3,.drawer__nav > li > a{
  font-family:$heading_font_family; 
  font-weight: 600;
}
.drawer__accountNav li a img, .drawer__accountNav li a img{
  width: 20px;
}
.drawer__close{
  background:#EE6B00;
}
.sec-concept .container{
  text-align: center;
}
@media (min-width: 992px){
  .hdrMiddle__right{
    display: flex;
    column-gap:150px;
  }
  .hdrMiddle__right .hdrInfo .material-icons{
    margin-right: 8px;
    font-size: 22px;
    vertical-align: middle;
  }
  .sec-concept .h2C,.sec-concept .h2C small, .sec-concept p{
    text-align: left;
  }
}
@media (max-width: 991px){
  .hdrMiddle__left .iconNav .menuToggle img{
    width: 30px;
  }
  .searchForm.searchFormB .searchForm__inner{
    border-radius: 0;
  }
}
@media screen and (min-width: 768px){
  .sec-banner .row a{
    width: calc((100% - 30px) / 2);
    display: block;
  }
  .itemList--ranking .itemList__unit:nth-child(-n + 3){
    flex: 0 0 33%;
    max-width: 33%;
  }
  .itemList--ranking .itemList__unit:nth-child(n + 4) .itemWrap::before{
    width:40px;
    height: 82px;  
    top:-30px;
  }
}
@media screen and (max-width: 767px){
  .row{
    gap: 30px;
  }
  .itemList--ranking{
    margin-bottom: 40px;
  }
  .itemList--ranking .itemList__unit .itemWrap::before{
    width:30px;
    height: 60px;
    top:-25px;
    left:10px;
  }
  .itemList--ranking .itemList__unit:nth-child(-n + 2){
    flex: 0 0 50%;
    max-width: 50%;
  }
  .itemList--ranking .itemList__unit:nth-child(n + 6){
    display: none;
  }
  .itemList--ranking .itemList__unit:nth-child(n + 3) .itemWrap::before{
    width:22px;
    height: 45px;  
    top:-15px;
  }
  .sec-concept .container, .sec-shop .container{
    padding: 0;
  }
  .sec-concept .h2C{
    margin-bottom: 20px;
  }
  .sec-concept .concept-text{
    width: 80%;
    margin:0 auto 30px;
  }
  .sec-shop .row{
    gap: 5px;
  }
  .sec-shop .h3A{
    font-size: 18px;
    padding: 0 15px;
  }
  .sec-shop .h3A a{
    display: none;
  }
  .sec-shop .col-pc-8 > p{
    padding: 0 15px;
  }
  .sec-shop .container > picture img{
    margin-bottom: 40px;
  }
  #ftr #drawer, .fNav__list li.hasChildren--click > a{
    background-color: #FEFAF4;
    font-weight: 400;
  }
  #ftr #drawer, .fNav__list li.hasChildren--click > a::after{
     border-color: #363636!important;
  }
}

