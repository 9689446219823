.exNav{
  ul{
    height: 40px;
    min-height: 40px;
    @media all and (-ms-high-contrast:none){
      height: auto;
      min-height: auto;
    }
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li{
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
      a{
        position: relative;
        display: block;
        line-height: 1;
        &:hover{
          text-decoration: none;
          color: $color_primary;
        }
      }
    }
  }

  &.exNav--typeA{
    ul li{
      a{
        padding-left: 20px;
        &:after{
          content: "";
          left: 0;
        }
      }
    }
  }

  &.exNav--typeB{
    ul li{
      &:not(:first-child){
        padding-left: 20px;
        border-left: 1px solid $color-text;
      }
    }
  }
}
