.mainSliderSec{
  overflow: hidden;
  &:not([data-fade="true"]){
    .mainSliderSec__inner{
      max-width: $container+px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }
  }
  &[data-fade="true"]{
    .mainSliderSec__inner{
      overflow: hidden;
    }
  }
  .slick-list{
    overflow: visible;
    img{
      max-width: 100%;
      margin: auto;
    }
  }
}
[data-dot="false"]{
  .slick-dots{
    display: none !important;
  }
}
.mainSlider[data-center="true"]{
  .slick-slide{
    padding: 0 $base_grid_margin+px;
    @media (max-width: 767px) {
      padding: 0 $sp_base_grid_margin+px;
    }
  }
}
.mainSlider--typeB{
  margin-bottom: $base_grid_margin * 2+px;
}
@media (max-width: $container+px) {
  .msThumbnail{
    padding: 0 $sp_bezel+px;
  }
}
@media (max-width: $braekpoint_tab+px) {
  .mainSlider--typeB{
    margin-bottom: $sp_base_grid_margin * 2+px;
  }
}

.mainSlider,.mainSlider--typeB{
  &[data-arrow="false"]{
    .slick-arrow{
       display: none !important;
    }
  }
  .slick-arrow{
      transform-origin: top left top left;
      margin: 0;
      display: block;
    &:before{
      content: none;
    }
    width: 40px;
    height: 40px;
    transform-origin: top;
  }
  .slick-next,.slick-prev{
    z-index: 2;
  }
  .slick-next{
    transform: rotate(45deg) translate(0, -50%);
    border-top: $color_text 1px solid;
    border-right: $color_text 1px solid;
    right: 30px;
  }
  .slick-prev{
    transform: rotate(45deg) translate(0, -50%);
    border-bottom: $color_text 1px solid;
    border-left: $color_text 1px solid;
    left: 30px;
  }
  @media (max-width: $container + 40+px){
    .slick-arrow{
      width: 20px;
      height: 20px;
          transform-origin: top;
    }
    .slick-next{
      right: 15px;
    }
    .slick-prev{
      left: 15px;
    }
  }
}

#mainSlider:not(.slick-initialized){
  height: 500px;
  >*{
    opacity: 0;
  }
  position: relative;
  &:before{
    content: "Loading...";
    position: absolute;
    top: 50%;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    color: $color_primary;
    font-size: 20px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.dotsA {
	.slick-dots {
		bottom: 20px;
		right: 25px;
		text-align: right;
    li,li button,li button::before{
      width: 35px;
      height: 4px;
    }
		li {
			margin: 0 5px;
			button {
				&::before {
					border-radius: 0;
				}
			}
		}
	}
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}


#scrollBtn{
  position: absolute;
  bottom: 50px;
  @if $device_mode != 'pc'{
    @media screen and (max-width:$braekpoint_tab+px){
      bottom: 20px;
    }
  }
  left: 0;
  right: 0;
  margin: auto;
  width: 80px;
  text-align: center;
  z-index: 4;
  font-size: 10px;
  height: 24px;
  font-weight: bold;
  &:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 15px;
    height: 15px;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    transform: rotate(135deg);
    margin: auto;
  }
  &.scrollBtn--wh{
    color: #fff;
    &:before{
      border-color: #fff;
    }
  }
}


.mainSliderSec__inner.inner_margin {
  ul.mainSlider {
    // width: 50%;
    // max-width: 50%;
    margin: 0 auto 35px;
    position: initial;
    .slick-dots {
      left: 0;
    }
  }
}

.mainSlidermargin {
	li {
		margin: 0 15px;
		@media (max-width: $container+px) {
			margin: 0 7.5px;
		}
	}
}

.loaded-js{
  opacity: 0;
  -webkit-transition: opacity 1.5s ease .3s;
  transition: opacity 1.5s ease .3s;
  &.loaded{
    opacity: 1;
  }
}

.mainSlider[data-fade="true"] {
  position: relative;
  > .slick-list{
    overflow: hidden;
  }
  > .slick-list, > .slick-list > .slick-track, > .slick-list > .slick-track > .slick-slide > div {
    position: relative;
    height: 100%;
  }
  > li {
    position: relative;
    height: 100%;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 100%;
    }
  }
}

.fadeAnimetion{
    animation: fadezoom 8s 0s forwards;
    animation-timing-function:ease-out;
}

@keyframes fadezoom {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
