#modalDetailSearch{
  opacity: 0;
  pointer-events: none;
  &.open{
    opacity: 1;
    pointer-events: all;
    transition: 0.15s;
  }
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  >form{
    width: 600px;
    max-width: calc(100% - 30px);
    max-height: 90vh;
    padding: 30px;
    background: #fff;
    z-index: 3;
    position: relative;
  }
  ul.ccSearch > li {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

#mdsBg,
#mdsClose{
  position: absolute;
  cursor: pointer;
  text-align: right;
  i{
    display: inline-block;
  }
}
#mdsBg{
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
}
#mdsClose{
  z-index: 2;

  text-align: right;
  background: transparent;
  border: none;
  color: #fff;
  top: -40px;
  right: 0px;
  i{
  }
}
