#side{
  img{
    max-width: 100%;
  }
  .hasChildren,
  .hasChildren--click{
    > ul{
      display: none;
      li{
        background: $color_highlight_bg;
        padding: 0 0px 0 12px;
        &:last-child{
          border-bottom: 0;
        }
      }

    }
  }

  .hasChildren{
    >a{
      &:after{
        transform: rotate(135deg) translateY(-50%);
      }
      &.open{
        &:after{
          transform: rotate(-45deg) translateY(-50%);
        }
      }
      &:hover{
        &:after{
          right: 15px;
          top: calc(50% + 3px);
        }
      }
    }
  }


  .snsNav{
    justify-content: center;
  }
}

.side__search{
  margin-bottom: 30px;
  .searchForm__inner{
    width: 100%;
  }
}

.sideTitle{
  font-size: 16px;
  font-weight: $head_font_weight;
  line-height: 24px;
  padding: 12px 0;
  margin-bottom: 0;
}

.side__bnr{
  display: block;
  margin-bottom: 20px;
  a{
    display: block;
  }
  .row{
    margin-left: -7px;
    margin-right: -7px;
  }
  [class*=col]{
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 14px;
  }
}

.side__cateNav{
  margin-bottom: 30px;
  >ul>li>a{

  }
  ul{
    list-style: none;
    border-top: 1px solid $color_border;
    li{
      list-style: none;
      border-bottom: 1px solid $color_border;
      a{
        display: block;
        line-height: 24px;
        padding: 12px;
        &:hover{
          background: $color_highlight_bg;
          color: $color_primary;
        }
      }
    }
  }
}

#side,.cateList{
  .hasChildren--click{
    >h3,
    >a{
      position: relative;
      cursor: pointer;
      &:before,
      &:after{
        width: 10px;
        height: 2px;
        transform:translateY(-50%);
        border: 0;
        right: 13px;
        background: $color_text;
        display: block;
        position: absolute;
        transition: 0.15s;
        top: 50%;
      }

      &:after{
        content: "";
      }

      &:before{
        content: "";
        display: block;
        position: absolute;
        transition: 0.15s;
        top: 50%;
        transform: rotate(90deg) translateY(-50%);
        transform-origin: top center;
      }
      &.open{
        &:before,
        &:after{
        }
        &:before{
          opacity: 0;
          transform: translateY(-50%);
        }
        &:after{
        }
      }
      &:hover{
        &:before,
        &:after{
          background: $color_primary;
          right: 13px !important;
        }
        &:after{
        }
      }
    }
  }
}

.side__txtBnr{
  text-align: center;
  padding: 10px 20px;
  display: block;
  border: 5px solid $color_primary;
  strong{
    font-size: 16px;display: block;
    line-height: 24px;
  }
  small{
    font-size: 12px;display: block;
    line-height: 24px;
    color: $color_primary;
  }
  &:before{
    content: "";
    display: block;
    top: 50%;
    right: 9px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    background: $color_primary;
    transform: translateY(-50%);
    transition: 0.2s;
  }
  &:after{
    border-color: #fff !important;
  }
  &:hover{
    &:before{
      right: 6px;
      transition: 0.2s;
    }
  }
}

.side__txtBnr--typeB{
  background: $color_primary;
  color: #fff;
  &:before{
    background: #fff;
  }
  &:after{
    border-color: $color_primary !important;
  }
  strong,
  small{
    color: #fff;
  }
}

.side__cateNav .hasChildren--hover {
  position:relative;
  > ul {
    position:absolute;
    top: -1px;
    left: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    border: 1px solid $color_border;
    box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.25);
    width: 250px;
    padding: 10px;
    > li {
      border-bottom: transparent;
      &.hasChildren--hover-img a:after {
        content: none!important;
      }
      >a{
        padding: 5px;
        &:hover {
          color: $color_primary;
          background-color: #fff;
          &:after {
            border-top: 2px solid $color_primary;
            border-right: 2px solid $color_primary;
          }
        }
      }
    }
  }
  &:hover > ul{
    opacity:1;
    visibility: visible;
  }
}

.side__cateNav + .side__cateNav{
  margin-top: 15px;
}
