$mainVisual__height--pc: 714; //PC時の画像の高さ

.mainVisual{
  overflow: hidden;
  position: relative;
  @if $device_mode != 'sp'{
    height: $mainVisual__height--pc+px;
  }
  @if $device_mode == 'responsive'{
    @media (max-width: $braekpoint_sp1 - 1+px) {
      height: auto;
    }
  }
  .mainVisual__inner{
    img{
      display: block;
      position: absolute;
      left: -300%;
      right: -300%;
      top: 0;
      bottom: 0;
      margin: auto;
      max-width: none;
      z-index: 2;
    }
    @if $device_mode != 'pc'{
      @media screen and (max-width:750px){
        img{
          position: relative;
          left: inherit;
          right: inherit;
          max-width: 100%;
        }
      }
    }
  }


  &.mainVisual--hero{
    height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);
    @media screen and (max-width:750px){
      .mainVisual__inner{
        img{
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          height: 100%;
          height: calc(var(--vh, 1vh) * 100);
          width: 100%;
          -o-object-fit: cover;
             object-fit: cover;
        }
      }
    }
  }
}

.mainVisual__copy{
  img{
    display: block;
    position: absolute;
    max-width: 100%;
    @media screen and (max-width:$braekpoint_tab+px){
      max-width: calc(100% - 50px);
    }
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 3;
  }
}

.mainVisual--arrangeA{

}
.mvA_inner{
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img{
    max-width: 100%;
    display: block;
    margin: auto;
  }
  a{
    display: block;
    opacity: 1;
    &:hover{
      opacity: 0.8;
    }
  }
}
.mvA_main,
.mvA_sub{
  max-width: 50%;
  flex: 0 0 50%;
  @if $device_mode != 'pc'{
    @media (max-width: $braekpoint_sp1 - 1+px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

.mvA_sub{
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  >*{
    max-width: 50% !important;
    flex: 0 0 50%;

    position: relative;
    >span{
      position: absolute;
      left: 0;right: 0;bottom: 0;
      padding: 5px 7px;
      font-size: 12px;
      line-height: 20px;
      background: rgba(0,0,0,0.5);
      color: #fff;
      @media screen and (max-width:$container+px){
        font-size: 10px;
        padding: 3px 5px;
      }

    }
  }
}


.mainVisual--arrangeB {
  padding: 0;
	.container {
		display: flex;
		@media (max-width: $braekpoint_sp1 - 1+px) {
			flex-wrap: wrap;

		}
	}
  a {
    display: block;
    width: 100%;
  }
  img {
    max-width: 100%;
  }
}
.mvB_main {
  @media (min-width: $braekpoint_sp1 +px) {
    flex: 0 0 63%;
    max-width: 63%;
  }
  margin-right:10px;
  @media (max-width: $braekpoint_sp1 - 1+px) {
    max-width: 100%;
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 6px;
  }
}
.mvB_sub {
  @media (min-width: $braekpoint_sp1 +px) {
    flex: 0 0 37%;
    max-width: 37%;
  }
  display: flex;
  flex-wrap: wrap;
  > a {
    margin-bottom: 10px;
  }
  a:last-child {
    margin-bottom: 0;
  }
  @media (max-width: $braekpoint_sp1 - 1+px) {
    > a {
      width: 49%;
      margin-bottom: 0px;
      margin-right :2%
    }
    a:last-child {
      margin-right: 0;
    }
  }
}
