.iframe__wrap{
  position:relative;
  width:100%;
  height:0;
  padding-top:66%;
  &.narrow{
    padding-top:40%;
  }
  &.xnarrow{
    padding-top:25%;
  }
  &.square{
    padding-top:100%;
  }
  @media (max-width: 767px) {
    padding-top:66%;
    &.xnarrow{
      padding-top:40%;
    }
  }
  iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}
.iframe__caption{
  margin-top: 0.5em;
}

.fbWrap{
  text-align: center;
  width: 100%;
  overflow: hidden;
}
