.bnrList{
  a{
    display: block;
    img{
      transition: 0.15s;
    }
    &:hover{
      color: $color_primary;
      img{
        opacity: 0.65;
        transition: 0.15s;
      }
    }
  }

  &.contentsSlider{
    margin: 0 -15px;
    .slick-slide{
      margin: 0 15px;
    }
    @media (max-width: $braekpoint_tab+px){
      margin: 0 -7px;
      .slick-slide{
        margin: 0 7px;
      }
    }
  }
}
.bnrBox{
  transition: 0.15s;
  display: block;
  &:hover{
    opacity: 0.65;
    transition: 0.15s;
  }
}
.bnr,.bnrBox img{
  display: block;
  max-width: 100% !important;
  margin:auto;
}

.bnrBox{
  h3{
    font-size: 14px;
    line-height: 24px;
    margin: 10px 0 5px;
  }
  p{

  }
  img + *{
    margin-top: 10px;
  }
}

.dotsB {
	.slick-dots {
		text-align: right;
		li {
			width: 8px;
			height: 8px;
			margin: 0 8px;
			button {
				width: 8px;
				height: 8px;
				&:before {
					width: 8px;
					height: 8px;
				}
			}
		}
	}
}


.rightVissibleSlider {
	overflow: hidden;
  .container,
  .slick-list {
  	overflow: visible;
  }
  .container {
    position: relative;
    // &:after {
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   top: 0;
    //   right: 100%;
    //   background-color: #fff;
    // }
  }
  @media (max-width: $container - 1+px) {
    .container {
      position: relative;
      &:after {
        content: unset;
      }
      .slick-list {
        padding-right: 50px;
      }
    }
  }
}
