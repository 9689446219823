@mixin itemArrangeDevice(){
  .itemArrange__wrap{
      display: -ms-flex;
      display: flex;
      flex-wrap: wrap;
    >*{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .itemArrange--typeA >*:nth-child(even){
    .itemArrange__img{
      order: 2;
      &:after{
        bottom: auto;
        top: -40px;
      }
    }
    .itemArrange__text{
      margin-bottom: 30px !important;
    }
  }
}
@if $device_mode != 'pc'{
  @media (min-width: $braekpoint_sp1+px) {
    @include itemArrangeDevice();
  }
}@else{
  @include itemArrangeDevice();
}

.itemArrange{
  h3{
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 12px;
  }
  *:last-child{
    margin-bottom: 0 !important;
  }
}

.itemArrange--typeA {
  .itemArrange__img {
    &:after{
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 100px;
      background: $color_text;
      right: 40px;
      bottom: -60px;
    }
  }
}
.itemArrange--typeB {
  .itemArrange__wrap {
    padding: 30px;
    .itemArrange__img {
      flex: 0 0 44%;
      max-width: 44%;
      margin-bottom: 0px;
    }
    .itemArrange__text {
      flex: 0 0 52%;
      max-width: 52%;
      margin-left: 4%;
      p {
        margin-bottom: 20px;
      }
      .itemArrange__price {
        letter-spacing: 1px;
        line-height: 24px;
        font-size: 22px;
        small {
          padding-left: 3px;
          font-size: 10px;
        }
      }
      .button {
        max-width: 160px;
      }
    }
  }
}
.itemArrange--typeE {
  .itemArrange__text {
    padding: 0 10px;
    h3 {
      margin-bottom: 5px;
      font-size: 24px;
    }
  }
}
.itemArrange--typeC ,
.itemArrange--typeD {
  .itemArrange__img {
    margin-bottom: 0;
  }
  .itemArrange__text {
    padding: 40px;
    .h2C {
      line-height: 24px;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .button {
      max-width: 200px;
    }
  }
}
.itemArrange--typeD_row {
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  &.itemArrange__text--left {
    flex-direction: row-reverse;
  }
  .itemArrange__img {
    position: relative;
    flex: 0 0 57%;
    max-width: 57%;
    min-height: 1px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .itemArrange__text {
    flex: 0 0 43%;
    max-width: 43%;
  }
}

.itemArrange__img{
  display: block;
  margin-bottom: 20px;
  position: relative;
}

@if $device_mode != 'pc'{
  @media screen and (max-width: $braekpoint_sp1 - 1+px){
    .itemArrange{
      h3{
        font-size: 18px;
      }
      [class*=col]{
        margin-bottom: 30px;
      }
    }
    .itemArrange--typeB {
      .itemArrange__wrap{
        padding: 15px;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        >*{
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }
        .itemArrange__text{
          padding: 20px;
          margin-left: 0 ;
        }
      }
    }
    .itemArrange--typeD {
      .itemArrange--typeD_row {
        .itemArrange__img {
          flex: 0 0 100%;
          max-width: 100%;
          .conceptF__bg {
            position: static;
            -o-object-fit: inherit;
            object-fit: inherit;
          }
        }
        .itemArrange__text {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .itemArrange__text {
        padding: 30px 20px;
        .text--right {
          text-align: left !important;
        }
      }
    }
    .itemArrange__text {
      .button{
        display: block;
        span{
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
  }
}


.itemArrange--typeG{
  p + .itemArrange__price{
    margin-top: -10px;
  }
  .itemArrange__price{
    font-weight: $strong_font_weight;
    font-size: 16px;
    small{
      font-size: 12px;
      font-weight: $base_font_weight;
    }
  }
}
.itemArrange__img img {
    display: block;
}
