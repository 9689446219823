.row,
.itemList {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-right: -$base_grid_margin+px;
  margin-left: -$base_grid_margin+px;
  @media (max-width: $container+px) {
    margin-right: -$sp_base_grid_margin+px;
    margin-left: -$sp_base_grid_margin+px;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-1_5, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sp2-1, .col-sp2-2, .col-sp2-3, .col-sp2-4, .col-sp2-5, .col-sp2-6, .col-sp2-7, .col-sp2-8, .col-sp2-9, .col-sp2-10, .col-sp2-11, .col-sp2-12, .col-sp2,
.col-sp2-auto, .col-sp-1, .col-sp-2, .col-sp-1_5, .col-sp-3, .col-sp-4, .col-sp-5, .col-sp-6, .col-sp-7, .col-sp-8, .col-sp-9, .col-sp-10, .col-sp-11, .col-sp-12, .col-sp,
.col-sp-auto, .col-tab-1, .col-tab-2, .col-tab-1_5, .col-tab-3, .col-tab-4, .col-tab-5, .col-tab-6, .col-tab-7, .col-tab-8, .col-tab-9, .col-tab-10, .col-tab-11, .col-tab-12, .col-tab,
.col-tab-auto, .col-pc-1, .col-pc-2,.col-pc-1_5, .col-pc-3, .col-pc-4, .col-pc-5, .col-pc-6, .col-pc-7, .col-pc-8, .col-pc-9, .col-pc-10, .col-pc-11, .col-pc-12, .col-pc,
.col-pc-auto, .itemList__unit {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: $base_grid_margin+px;
  padding-left: $base_grid_margin+px;
  margin-bottom: $base_grid_margin*2+px;
  list-style: none;
  @media (max-width: $container+px) {
    margin-bottom: $sp_base_grid_margin*2+px;
    padding-right: $sp_base_grid_margin+px;
    padding-left: $sp_base_grid_margin+px;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2, .itemList--6 .itemList__unit {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-1_5, .itemList--5 .itemList__unit {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-3, .itemList--4 .itemList__unit {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4, .itemList--3 .itemList__unit {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6, .itemList--2 .itemList__unit {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12, .itemList--1 .itemList__unit {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@if $device_mode != 'pc'{

    .col-pc {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-pc-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-pc-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-pc-2 , .itemList-pc--6 .itemList__unit {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    .col-pc-1_5 , .itemList-pc--5 .itemList__unit {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .col-pc-3 , .itemList-pc--4 .itemList__unit {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-pc-4 , .itemList-pc--3 .itemList__unit {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-pc-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-pc-6 , .itemList-pc--2 .itemList__unit {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-pc-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-pc-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-pc-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-pc-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-pc-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-pc-12 , .itemList-pc--1 .itemList__unit {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-pc-first {
      order: -1;
    }
    .order-pc-last {
      order: 13;
    }
    .order-pc-0 {
      order: 0;
    }
    .order-pc-1 {
      order: 1;
    }
    .order-pc-2 {
      order: 2;
    }
    .order-pc-3 {
      order: 3;
    }
    .order-pc-4 {
      order: 4;
    }
    .order-pc-5 {
      order: 5;
    }
    .order-pc-6 {
      order: 6;
    }
    .order-pc-7 {
      order: 7;
    }
    .order-pc-8 {
      order: 8;
    }
    .order-pc-9 {
      order: 9;
    }
    .order-pc-10 {
      order: 10;
    }
    .order-pc-11 {
      order: 11;
    }
    .order-pc-12 {
      order: 12;
    }
    .offset-pc-0 {
      margin-left: 0;
    }
    .offset-pc-1 {
      margin-left: 8.333333%;
    }
    .offset-pc-2 {
      margin-left: 16.666667%;
    }
    .offset-pc-3 {
      margin-left: 25%;
    }
    .offset-pc-4 {
      margin-left: 33.333333%;
    }
    .offset-pc-5 {
      margin-left: 41.666667%;
    }
    .offset-pc-6 {
      margin-left: 50%;
    }
    .offset-pc-7 {
      margin-left: 58.333333%;
    }
    .offset-pc-8 {
      margin-left: 66.666667%;
    }
    .offset-pc-9 {
      margin-left: 75%;
    }
    .offset-pc-10 {
      margin-left: 83.333333%;
    }
    .offset-pc-11 {
      margin-left: 91.666667%;
    }

  @media (max-width: $braekpoint_tab - 1+px) {
    .col-tab {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-tab-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-tab-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-tab-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-tab-3 , .itemList-tab--4 .itemList__unit {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-tab-4 , .itemList-tab--3 .itemList__unit {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-tab-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-tab-6 , .itemList-tab--2 .itemList__unit {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-tab-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-tab-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-tab-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-tab-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-tab-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-tab-12 , .itemList-tab--1 .itemList__unit {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-tab-first {
      order: -1;
    }
    .order-tab-last {
      order: 13;
    }
    .order-tab-0 {
      order: 0;
    }
    .order-tab-1 {
      order: 1;
    }
    .order-tab-2 {
      order: 2;
    }
    .order-tab-3 {
      order: 3;
    }
    .order-tab-4 {
      order: 4;
    }
    .order-tab-5 {
      order: 5;
    }
    .order-tab-6 {
      order: 6;
    }
    .order-tab-7 {
      order: 7;
    }
    .order-tab-8 {
      order: 8;
    }
    .order-tab-9 {
      order: 9;
    }
    .order-tab-10 {
      order: 10;
    }
    .order-tab-11 {
      order: 11;
    }
    .order-tab-12 {
      order: 12;
    }
    .offset-tab-0 {
      margin-left: 0;
    }
    .offset-tab-1 {
      margin-left: 8.333333%;
    }
    .offset-tab-2 {
      margin-left: 16.666667%;
    }
    .offset-tab-3 {
      margin-left: 25%;
    }
    .offset-tab-4 {
      margin-left: 33.333333%;
    }
    .offset-tab-5 {
      margin-left: 41.666667%;
    }
    .offset-tab-6 {
      margin-left: 50%;
    }
    .offset-tab-7 {
      margin-left: 58.333333%;
    }
    .offset-tab-8 {
      margin-left: 66.666667%;
    }
    .offset-tab-9 {
      margin-left: 75%;
    }
    .offset-tab-10 {
      margin-left: 83.333333%;
    }
    .offset-tab-11 {
      margin-left: 91.666667%;
    }
  }

  @media (max-width: $braekpoint_sp1 - 1+px) {
    .col-sp {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sp-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sp-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-sp-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-sp-3 , .itemList-sp--4 .itemList__unit {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sp-4 , .itemList-sp--3 .itemList__unit {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-sp-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-sp-6 , .itemList-sp--2 .itemList__unit {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sp-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-sp-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-sp-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sp-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-sp-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-sp-12 , .itemList-sp--1 .itemList__unit {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sp-first {
      order: -1;
    }
    .order-sp-last {
      order: 13;
    }
    .order-sp-0 {
      order: 0;
    }
    .order-sp-1 {
      order: 1;
    }
    .order-sp-2 {
      order: 2;
    }
    .order-sp-3 {
      order: 3;
    }
    .order-sp-4 {
      order: 4;
    }
    .order-sp-5 {
      order: 5;
    }
    .order-sp-6 {
      order: 6;
    }
    .order-sp-7 {
      order: 7;
    }
    .order-sp-8 {
      order: 8;
    }
    .order-sp-9 {
      order: 9;
    }
    .order-sp-10 {
      order: 10;
    }
    .order-sp-11 {
      order: 11;
    }
    .order-sp-12 {
      order: 12;
    }
    .offset-sp-0 {
      margin-left: 0;
    }
    .offset-sp-1 {
      margin-left: 8.333333%;
    }
    .offset-sp-2 {
      margin-left: 16.666667%;
    }
    .offset-sp-3 {
      margin-left: 25%;
    }
    .offset-sp-4 {
      margin-left: 33.333333%;
    }
    .offset-sp-5 {
      margin-left: 41.666667%;
    }
    .offset-sp-6 {
      margin-left: 50%;
    }
    .offset-sp-7 {
      margin-left: 58.333333%;
    }
    .offset-sp-8 {
      margin-left: 66.666667%;
    }
    .offset-sp-9 {
      margin-left: 75%;
    }
    .offset-sp-10 {
      margin-left: 83.333333%;
    }
    .offset-sp-11 {
      margin-left: 91.666667%;
    }


    .itemList-sp--3,
    .itemList.itemList--typeC,
    .itemList.itemList--typeD{
      margin-left: -5px;
      margin-right: -5px;
    }

    .itemList-sp--3 .itemList__unit,
    .itemList.itemList--typeC .itemList__unit,
    .itemList.itemList--typeD .itemList__unit{
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;
    }

    .itemList-sp--3 .itemList__unit p ,
    .itemList.itemList--typeC .itemList__unit p,
    .itemList.itemList--typeD .itemList__unit p{
      font-size: 12px;
      line-height: 20px;
    }

  }

  @media (max-width: $braekpoint_sp2 - 1+px) {
    .col-sp2 {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sp2-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sp2-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-sp2-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-sp2-3 , .itemList-sp2--4 .itemList__unit {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sp2-4 , .itemList-sp2--3 .itemList__unit {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-sp2-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-sp2-6 , .itemList-sp2--2 .itemList__unit {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sp2-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-sp2-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-sp2-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sp2-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-sp2-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-sp2-12 , .itemList-sp2--1 .itemList__unit {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sp2-first {
      order: -1;
    }
    .order-sp2-last {
      order: 13;
    }
    .order-sp2-0 {
      order: 0;
    }
    .order-sp2-1 {
      order: 1;
    }
    .order-sp2-2 {
      order: 2;
    }
    .order-sp2-3 {
      order: 3;
    }
    .order-sp2-4 {
      order: 4;
    }
    .order-sp2-5 {
      order: 5;
    }
    .order-sp2-6 {
      order: 6;
    }
    .order-sp2-7 {
      order: 7;
    }
    .order-sp2-8 {
      order: 8;
    }
    .order-sp2-9 {
      order: 9;
    }
    .order-sp2-10 {
      order: 10;
    }
    .order-sp2-11 {
      order: 11;
    }
    .order-sp2-12 {
      order: 12;
    }
    .offset-sp2-0 {
      margin-left: 0;
    }
    .offset-sp2-1 {
      margin-left: 8.333333%;
    }
    .offset-sp2-2 {
      margin-left: 16.666667%;
    }
    .offset-sp2-3 {
      margin-left: 25%;
    }
    .offset-sp2-4 {
      margin-left: 33.333333%;
    }
    .offset-sp2-5 {
      margin-left: 41.666667%;
    }
    .offset-sp2-6 {
      margin-left: 50%;
    }
    .offset-sp2-7 {
      margin-left: 58.333333%;
    }
    .offset-sp2-8 {
      margin-left: 66.666667%;
    }
    .offset-sp2-9 {
      margin-left: 75%;
    }
    .offset-sp2-10 {
      margin-left: 83.333333%;
    }
    .offset-sp2-11 {
      margin-left: 91.666667%;
    }
  }



}

@if $device_mode == 'pc'{
  .col-pc {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-pc-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-pc-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-pc-2 , .itemList-pc--6 .itemList__unit {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-pc-1_5 , .itemList-pc--5 .itemList__unit {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-pc-3 , .itemList-pc--4 .itemList__unit {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-pc-4 , .itemList-pc--3 .itemList__unit {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-pc-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-pc-6 , .itemList-pc--2 .itemList__unit {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-pc-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-pc-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-pc-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-pc-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-pc-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-pc-12 , .itemList-pc--1 .itemList__unit {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-pc-first {
    order: -1;
  }
  .order-pc-last {
    order: 13;
  }
  .order-pc-0 {
    order: 0;
  }
  .order-pc-1 {
    order: 1;
  }
  .order-pc-2 {
    order: 2;
  }
  .order-pc-3 {
    order: 3;
  }
  .order-pc-4 {
    order: 4;
  }
  .order-pc-5 {
    order: 5;
  }
  .order-pc-6 {
    order: 6;
  }
  .order-pc-7 {
    order: 7;
  }
  .order-pc-8 {
    order: 8;
  }
  .order-pc-9 {
    order: 9;
  }
  .order-pc-10 {
    order: 10;
  }
  .order-pc-11 {
    order: 11;
  }
  .order-pc-12 {
    order: 12;
  }
  .offset-pc-0 {
    margin-left: 0;
  }
  .offset-pc-1 {
    margin-left: 8.333333%;
  }
  .offset-pc-2 {
    margin-left: 16.666667%;
  }
  .offset-pc-3 {
    margin-left: 25%;
  }
  .offset-pc-4 {
    margin-left: 33.333333%;
  }
  .offset-pc-5 {
    margin-left: 41.666667%;
  }
  .offset-pc-6 {
    margin-left: 50%;
  }
  .offset-pc-7 {
    margin-left: 58.333333%;
  }
  .offset-pc-8 {
    margin-left: 66.666667%;
  }
  .offset-pc-9 {
    margin-left: 75%;
  }
  .offset-pc-10 {
    margin-left: 83.333333%;
  }
  .offset-pc-11 {
    margin-left: 91.666667%;
  }

  @media (max-width: $braekpoint_tab - 1+px) {
    .col-tab {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-tab-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-tab-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-tab-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-tab-3 , .itemList-tab--4 .itemList__unit {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-tab-4 , .itemList-tab--3 .itemList__unit {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-tab-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-tab-6 , .itemList-tab--2 .itemList__unit {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-tab-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-tab-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-tab-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-tab-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-tab-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-tab-12 , .itemList-tab--1 .itemList__unit {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-tab-first {
      order: -1;
    }
    .order-tab-last {
      order: 13;
    }
    .order-tab-0 {
      order: 0;
    }
    .order-tab-1 {
      order: 1;
    }
    .order-tab-2 {
      order: 2;
    }
    .order-tab-3 {
      order: 3;
    }
    .order-tab-4 {
      order: 4;
    }
    .order-tab-5 {
      order: 5;
    }
    .order-tab-6 {
      order: 6;
    }
    .order-tab-7 {
      order: 7;
    }
    .order-tab-8 {
      order: 8;
    }
    .order-tab-9 {
      order: 9;
    }
    .order-tab-10 {
      order: 10;
    }
    .order-tab-11 {
      order: 11;
    }
    .order-tab-12 {
      order: 12;
    }
    .offset-tab-0 {
      margin-left: 0;
    }
    .offset-tab-1 {
      margin-left: 8.333333%;
    }
    .offset-tab-2 {
      margin-left: 16.666667%;
    }
    .offset-tab-3 {
      margin-left: 25%;
    }
    .offset-tab-4 {
      margin-left: 33.333333%;
    }
    .offset-tab-5 {
      margin-left: 41.666667%;
    }
    .offset-tab-6 {
      margin-left: 50%;
    }
    .offset-tab-7 {
      margin-left: 58.333333%;
    }
    .offset-tab-8 {
      margin-left: 66.666667%;
    }
    .offset-tab-9 {
      margin-left: 75%;
    }
    .offset-tab-10 {
      margin-left: 83.333333%;
    }
    .offset-tab-11 {
      margin-left: 91.666667%;
    }
  }
}
